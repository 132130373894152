import { Box, Button, Slider, IconButton, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useEffect, useState, useRef } from "react";
import { useWindowSize } from "../../../utilities";
import { Link } from "react-router-dom";

const DragableBox = ({ children, title }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [position, setPosition] = useState({ x: 100, y: 100 });
  const [dragging, setDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const boxRef = useRef(null); // Ref to the draggable box

  const toggleExpand = () => setIsExpanded((prev) => !prev);

  // Start dragging when the user clicks the header
  const handleDragStart = (e) => {
    const boxRect = boxRef.current.getBoundingClientRect(); // Get the entire box position
    setDragOffset({
      x: e.clientX - boxRect.left, // Offset from where the user clicked on the box
      y: e.clientY - boxRect.top
    });
    setDragging(true);
  };

  // Stop dragging when the mouse is released
  const handleDragEnd = () => {
    setDragging(false);
  };

  // Move the box as the user moves the mouse
  const handleMouseMove = (e) => {
    if (dragging) {
      setPosition({
        x: e.clientX - dragOffset.x,
        y: e.clientY - dragOffset.y
      });
    }
  };

  // Add global mousemove and mouseup listeners when dragging starts
  useEffect(() => {
    if (dragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleDragEnd);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleDragEnd);
    }

    // Cleanup event listeners on component unmount or when dragging stops
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleDragEnd);
    };
  }, [dragging]);

  return (
    <Box
      ref={boxRef} // Attach the ref to the draggable box
      sx={{
        position: "absolute",
        left: `${position.x}px`,
        top: `${position.y}px`,
        width: isExpanded ? "300px" : "150px",
        height: isExpanded ? "300px" : "30px",
        backgroundColor: "#C0C0C0", // Light gray background (Windows 95/98 style)
        border: "2px solid #808080", // Dark gray border
        boxShadow: "inset 1px 1px 0px #fff, inset -1px -1px 0px #808080", // 3D effect
        fontFamily: '"Arial", sans-serif', // Classic font
        cursor: dragging ? "grabbing" : "grab", // Change cursor during drag
        padding: "0px",
        zIndex: 1000
      }}
    >
      {/* Header */}
      <Box
        display="flex"
        alignItems="center"
        sx={{
          backgroundColor: "#000080", // Dark blue header (Internet Explorer-style)
          color: "#ffffff", // White text in the header
          padding: "4px",
          borderBottom: "1px solid #808080",
          cursor: "grab"
        }}
        onMouseDown={handleDragStart} // Start dragging when the user clicks the header
      >
        <Typography
          sx={{
            flexGrow: 1,
            fontSize: "12px",
            paddingLeft: "5px"
          }}
        >
          {title}
        </Typography>
        <IconButton sx={{ padding: "0px", color: "#ffffff" }} onClick={toggleExpand}>
          {!isExpanded ? <ExpandMoreIcon fontSize="small" /> : <ExpandLessIcon fontSize="small" />}
        </IconButton>
      </Box>

      {isExpanded && (
        <Box
          sx={{
            padding: "10px",
            backgroundColor: "#FFFFFF",
            border: "1px solid #808080",
            boxShadow: "inset 1px 1px 0px #fff, inset -1px -1px 0px #808080",
            fontSize: "12px",
            color: "#000"
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};
const HeaderBox = () => {
  return (
    <DragableBox title="Brandon McHugh">
      <Button
        fontFamily="roboto slab"
        color="inherit"
        component={Link}
        to={"/aboutme"}
        sx={{ marginX: 3 }}
      >
        About Me
      </Button>
      <Button
        fontFamily="roboto slab"
        color="inherit"
        component={Link}
        to={"/experience"}
        sx={{ marginX: 3 }}
      >
        Experience
      </Button>
      <Button
        fontFamily="roboto slab"
        color="inherit"
        component={Link}
        to={"/projects"}
        sx={{ marginX: 3 }}
      >
        Projects
      </Button>
    </DragableBox>
  );
};

const AboutMeBox = () => {
  return <DragableBox title="About Me"></DragableBox>;
};

const SettingsBox = ({
  startTime,
  reset,
  random,
  speed,
  handleSpeedChange,
  handleShowBorders,
  handleSetBorders
}) => {
  return (
    <DragableBox title="Settings">
      <Button variant="outlined" onClick={startTime} sx={{ marginBottom: "5px" }}>
        Start Evolution
      </Button>
      <Button variant="outlined" onClick={reset} sx={{ marginBottom: "5px" }}>
        Reset
      </Button>
      <Button variant="outlined" onClick={random} sx={{ marginBottom: "5px" }}>
        Random Board
      </Button>
      <Box width={300} marginLeft={10}>
        <Slider defaultValue={50} min={1} value={speed} onChange={handleSpeedChange} />
      </Box>
      <Button variant="outlined" onClick={handleShowBorders} sx={{ marginBottom: "5px" }}>
        Show Borders
      </Button>
      <Button variant="outlined" onClick={handleSetBorders}>
        Use Borders
      </Button>
    </DragableBox>
  );
};

function lookAround([i, k], max, useBorders) {
  let { height, width } = max;
  const around = [];
  const positions = [-1, 0, 1];

  if (useBorders) {
    positions.forEach((dx) => {
      positions.forEach((dy) => {
        if (dx !== 0 || dy !== 0) {
          const ni = i + dx;
          const nk = k + dy;

          // Only add neighbors that are within the grid boundaries
          if (ni >= 0 && ni < height && nk >= 0 && nk < width) {
            around.push([ni, nk]);
          }
        }
      });
    });
  } else {
    positions.forEach((dx) => {
      positions.forEach((dy) => {
        if (dx !== 0 || dy !== 0) {
          const ni = (i + dx + height) % height;
          const nk = (k + dy + width) % width;
          around.push([ni, nk]);
        }
      });
    });
  }

  return around;
}

const Life = () => {
  document.body.style.overflow = "hidden";
  const organismSize = 20;
  const { width, height } = useWindowSize();
  const [aliveCells, setAliveCells] = useState(new Set());
  const [timeGoing, setTimeGoing] = useState(false);
  const [speed, setSpeed] = useState(50);
  const [showBorders, setShowBorders] = useState(true);
  const [useBorders, setBorders] = useState(true);
  const worldSize = {
    height: Math.ceil(height / organismSize),
    width: Math.ceil(width / organismSize)
  };

  const handleSetBorders = () => setBorders(!useBorders);
  const handleSpeedChange = (e, newValue) => setSpeed(newValue);
  const handleShowBorders = () => setShowBorders(!showBorders);
  const startTime = () => setTimeGoing((prev) => !prev);

  const reset = () => {
    setTimeGoing(false);
    setAliveCells(new Set());
  };

  const random = () => {
    setTimeGoing(false);
    const newAliveCells = new Set();
    for (let i = 0; i < worldSize.height; i++) {
      for (let k = 0; k < worldSize.width; k++) {
        if (Math.random() < 0.5) {
          newAliveCells.add(`${i},${k}`);
        }
      }
    }
    setAliveCells(newAliveCells);
  };

  const updateBoard = (x, y) => {
    const key = `${x},${y}`;
    const newAliveCells = new Set(aliveCells);
    if (newAliveCells.has(key)) {
      newAliveCells.delete(key);
    } else {
      newAliveCells.add(key);
    }
    setAliveCells(newAliveCells);
  };

  const evolve = () => {
    const newAliveCells = new Set();
    const potentialCells = new Map();

    aliveCells.forEach((cell) => {
      const [x, y] = cell.split(",").map(Number);
      const neighbors = lookAround([x, y], worldSize, useBorders);

      neighbors.forEach(([i, k]) => {
        const key = `${i},${k}`;
        potentialCells.set(key, (potentialCells.get(key) || 0) + 1);
      });
    });

    potentialCells.forEach((count, cell) => {
      const isAlive = aliveCells.has(cell);
      if ((isAlive && (count === 2 || count === 3)) || (!isAlive && count === 3)) {
        newAliveCells.add(cell);
      }
    });

    setAliveCells(newAliveCells);
  };

  useEffect(() => {
    if (timeGoing && aliveCells.size > 0) {
      const interval = setInterval(evolve, 1050 - speed * 10);
      return () => clearInterval(interval);
    } else if (aliveCells.size === 0) {
      setTimeGoing(false);
    }
  }, [timeGoing, aliveCells, speed]);

  return (
    <Box maxHeight="100vh">
      <Box>
        {Array.from({ length: worldSize.height }, (_, x) => (
          <Box display="flex" key={x}>
            {Array.from({ length: worldSize.width }, (_, y) => {
              const isAlive = aliveCells.has(`${x},${y}`);
              return (
                <Box
                  key={y}
                  onClick={() => updateBoard(x, y)}
                  height={organismSize}
                  width={organismSize}
                  sx={{ boxSizing: "border-box" }}
                  border={showBorders && "1px solid #DCDCDC"}
                  backgroundColor={isAlive ? "black" : "white"}
                />
              );
            })}
          </Box>
        ))}
      </Box>
      <HeaderBox />
      <SettingsBox
        startTime={startTime}
        reset={reset}
        random={random}
        speed={speed}
        handleSpeedChange={handleSpeedChange}
        handleShowBorders={handleShowBorders}
        handleSetBorders={handleSetBorders}
      />
    </Box>
  );
};

export default Life;
