import React from "react";
import { AppBar, Toolbar, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

function Header({ fontColor = "black", backgroundColor = "white" }) {
  const LinkButton = ({ to, title }) => (
    <Button fontFamily="roboto slab" color="inherit" component={Link} to={to} sx={{ marginX: 3 }}>
      {title}
    </Button>
  );

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: backgroundColor,
        color: fontColor,
        // borderBottom: "1px solid #E4E4E4",
        boxShadow: 0
      }}
    >
      <Toolbar>
        <Typography
          variant="h5"
          sx={{ flexGrow: 1 }}
          component={Link}
          to="/"
          fontFamily="roboto slab"
          style={{ textDecoration: "none", color: fontColor }}
        >
          BRANDON McHUGH
        </Typography>
        <LinkButton title="About Me" to="/aboutme" sx={{ color: fontColor }} />
        <LinkButton title="Experience" to="/experience" sx={{ color: fontColor }} />
        <LinkButton title="Projects" to="/projects" sx={{ color: fontColor }} />
      </Toolbar>
    </AppBar>
  );
}

export default Header;
