import { Box, Card, CardContent, Typography, CardActionArea } from "@mui/material";
import Header from "../components/header";
import { Link } from "react-router-dom";
import projects from "../data/projects.json";

const Projects = () => {
  const getLink = (link) =>
    link === "https://www.supersimplescrum.com/"
      ? "https://www.supersimplescrum.com/"
      : "/projects" + link;
  return (
    <Box height="100vh" width="100vw" sx={{ backgroundColor: "#f5f7fa" }}>
      <Header />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        marginTop={4}
      >
        <Typography variant="h4" sx={{ fontFamily: "Roboto Slab", marginBottom: 3 }}>
          My Projects
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "1fr", // Single column for small screens
            sm: "repeat(2, 1fr)", // Two columns for medium screens
            md: "repeat(3, 1fr)" // Three columns for large screens
          }}
          gap={4}
          width="80%"
        >
          {projects.projects.map((project, index) => (
            <Card
              key={index}
              sx={{
                borderRadius: 2,
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)"
                }
              }}
            >
              <CardActionArea component={Link} to={getLink(project.link)} sx={{ height: "100%" }}>
                <CardContent sx={{ textAlign: "center", padding: 3 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Roboto Slab",
                      marginBottom: 1,
                      color: "#333"
                    }}
                  >
                    {project.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Roboto",
                      color: "#666"
                    }}
                  >
                    {project.description || "Click to view project"}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Projects;
