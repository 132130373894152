import { useState, useEffect, useRef } from "react";
import { Box, Typography, Link } from "@mui/material";
import Header from "../components/header";
import experienceData from "../data/experience.json";
import { fetchData, randomColor } from "../utilities";

const JobBox = ({ job }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Box
      onClick={handleExpand}
      display="flex"
      marginY={3}
      marginX={10}
      paddingX={3}
      paddingY={3}
      borderRadius={3}
      sx={{
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          transform: "scale(1.025)"
        },
        cursor: "pointer"
      }}
    >
      <Box flex={1} marginRight={2} display="flex" justifyContent="right">
        <Typography fontFamily="roboto slab" variant="caption" marginTop={0.5}>
          {job.years}
        </Typography>
      </Box>
      <Box flex={4} paddingRight={8}>
        <Typography fontFamily="roboto slab" variant="h5">
          {job.company}
        </Typography>
        <Typography fontFamily="roboto slab" variant="h6" gutterBottom>
          {job.position}
        </Typography>
        {expanded ? (
          <Box>
            {job.details.map((data, index) => (
              <Box key={index} display="flex">
                <Typography fontFamily="roboto slab" gutterBottom marginRight={1}>
                  -
                </Typography>
                <Typography fontFamily="roboto slab" gutterBottom>
                  {data}
                </Typography>
              </Box>
            ))}
          </Box>
        ) : (
          <Box>
            <Typography fontFamily="roboto slab">{job.summary}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const EducationBox = ({ edu }) => {
  const [textColor, setTextColor] = useState("#FFFFFF");

  const changeTextColor = () => {
    setTextColor(randomColor());
  };

  return (
    <Box
      display="flex"
      marginY={3}
      marginX={10}
      paddingX={3}
      paddingY={3}
      borderRadius={3}
      onClick={changeTextColor}
      sx={{
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          transform: "scale(1.025)"
        },
        cursor: "pointer"
      }}
    >
      <Box flex={1} marginRight={2} display="flex" justifyContent="right">
        <Typography
          fontFamily="roboto slab"
          variant="caption"
          marginTop={0.5}
          color={textColor}
          sx={{
            transition: "color 0.5s ease"
          }}
        >
          Graduated {edu.graduated}
        </Typography>
      </Box>
      <Box flex={4} paddingRight={8}>
        <Typography
          fontFamily="roboto slab"
          variant="h5"
          color={textColor}
          sx={{
            transition: "color 0.5s ease"
          }}
        >
          {edu.institution}
        </Typography>
        <Typography
          fontFamily="roboto slab"
          variant="h6"
          gutterBottom
          color={textColor}
          sx={{
            transition: "color 0.5s ease"
          }}
        >
          {edu.degree}
        </Typography>

        <Box>
          {edu.details.map((data, index) => (
            <Box key={index} display="flex">
              <Typography
                fontFamily="roboto slab"
                gutterBottom
                marginRight={1}
                color={textColor}
                sx={{
                  transition: "color 0.5s ease"
                }}
              >
                -
              </Typography>
              <Typography
                fontFamily="roboto slab"
                gutterBottom
                color={textColor}
                sx={{
                  transition: "color 0.5s ease"
                }}
              >
                {data}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

function Experience() {
  const [data, setData] = useState(experienceData);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [activeSection, setActiveSection] = useState(null);

  const skillsRef = useRef(null);
  const experienceRef = useRef(null);
  const educationRef = useRef(null);
  const certificatesRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, options);

    if (skillsRef.current) observer.observe(skillsRef.current);
    if (experienceRef.current) observer.observe(experienceRef.current);
    if (educationRef.current) observer.observe(educationRef.current);
    if (certificatesRef.current) observer.observe(certificatesRef.current);

    return () => {
      if (skillsRef.current) observer.unobserve(skillsRef.current);
      if (experienceRef.current) observer.unobserve(experienceRef.current);
      if (educationRef.current) observer.unobserve(educationRef.current);
      if (certificatesRef.current) observer.unobserve(certificatesRef.current);
    };
  }, []);

  const handleMouseMove = (event) => {
    setCursorPosition({
      x: event.clientX,
      y: event.clientY
    });
  };

  const css = {
    animatedLine: {
      transition: "width 0.15s ease-in-out" // Adds a smooth transition effect for width
    }
  };

  const Text = ({ children, variant, marginBottom }) => (
    <Typography fontFamily="roboto slab" variant={variant} marginBottom={marginBottom}>
      {children}
    </Typography>
  );

  const SkillLogo = ({ skill }) => {
    const skillPic = {
      JavaScript: "/static/js-logo.png",
      TypeScript: "/static/ts-logo.png",
      Python: "/static/python.png",
      React: "/static/React-icon.png",
      HTML: "/static/html.png",
      CSS: "/static/css.png",
      Nodejs: "/static/nodejs.png",
      Expressjs: "/static/expressjs.png",
      FastAPI: "/static/fastapi.png"
    };
    return (
      <img
        src={skillPic[skill]}
        alt={skill}
        title={skill}
        style={{ width: "100px", height: "100px", marginBottom: "25px" }}
      />
    );
  };

  // useEffect(() => {
  //   const getData = async () => {
  //     const res = await fetchData("/data/experience");
  //     res && setData(res);
  //   };
  //   getData();
  // }, []);

  return (
    <Box>
      {/* Set up background */}
      <Box
        fontFamily="roboto slab"
        backgroundColor="#111F44"
        className="spotlight-container"
        onMouseMove={handleMouseMove}
        sx={{
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          position: "relative"
        }}
      >
        <Box
          className="spotlight-background"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "#0F172A",
            backgroundSize: "cover",
            maskImage: `radial-gradient(circle 300px at ${cursorPosition.x}px ${cursorPosition.y}px, transparent 1px, rgba(0, 0, 0) 400px)`,
            WebkitMaskImage: `radial-gradient(circle 300px at ${cursorPosition.x}px ${cursorPosition.y}px, transparent 1px, rgba(0, 0, 0) 400px)`
          }}
        />
        <Header backgroundColor="#111F44" fontColor="#FFFFFF" />

        {/* Content */}
        <Box
          height="100%"
          display="flex"
          flex={1}
          sx={{
            position: "relative",
            zIndex: 2,
            padding: 2,
            color: "white"
          }}
        >
          {/* Left Side */}
          <Box
            sx={{
              height: "85vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column"
            }}
            flex={1}
          >
            <Box width="50%">
              <Box display="flex" alignItems="center" marginBottom={4}>
                <Box
                  width={activeSection === "skills" ? 80 : 40}
                  borderBottom="1px solid white"
                  marginRight={activeSection === "skills" ? 4 : 2}
                  sx={{ transition: "width 0.15s ease-in-out" }}
                />
                <Text fontFamily="roboto slab" variant="h6">
                  Skills
                </Text>
              </Box>

              <Box display="flex" alignItems="center" marginBottom={4}>
                <Box
                  width={activeSection === "experience" ? 80 : 40}
                  borderBottom="1px solid white"
                  marginRight={activeSection === "experience" ? 4 : 2}
                  sx={{ transition: "width 0.15s ease-in-out" }}
                />
                <Text fontFamily="roboto slab" variant="h6">
                  Experience
                </Text>
              </Box>

              <Box display="flex" alignItems="center" marginBottom={4}>
                <Box
                  width={activeSection === "education" ? 80 : 40}
                  borderBottom="1px solid white"
                  marginRight={activeSection === "education" ? 4 : 2}
                  sx={{ transition: "width 0.15s ease-in-out" }}
                />
                <Text variant="h6">Education</Text>
              </Box>

              <Box display="flex" alignItems="center" marginBottom={4}>
                <Box
                  width={activeSection === "certificates" ? 80 : 40}
                  borderBottom="1px solid white"
                  marginRight={activeSection === "certificates" ? 4 : 2}
                  sx={{ transition: "width 0.15s ease-in-out" }}
                />
                <Text variant="h6">Certificates</Text>
              </Box>
            </Box>
          </Box>

          {/* Right Side */}
          <Box
            flex={2}
            height="100%"
            overflow="scroll"
            sx={{
              "&::-webkit-scrollbar": {
                display: "none" // Hides scrollbar in Chrome, Safari, and Edge
              },
              "-ms-overflow-style": "none", // Hides scrollbar in IE and Edge
              "scrollbar-width": "none" // Hides scrollbar in Firefox
            }}
          >
            <Box>
              <Box
                id="skills"
                ref={skillsRef}
                display="flex"
                flexDirection="row"
                flex={1}
                height="90vh"
              >
                <Box flex={1} marginY={10} textAlign="center">
                  <Text variant="h4" marginBottom={3}>
                    Languages
                  </Text>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <SkillLogo skill="JavaScript" />
                    <SkillLogo skill="TypeScript" />
                    <SkillLogo skill="Python" />
                  </Box>
                </Box>
                <Box flex={1} marginY={10} textAlign="center">
                  <Text variant="h4" marginBottom={3}>
                    Frontend
                  </Text>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <SkillLogo skill="React" />
                    <SkillLogo skill="HTML" />
                    <SkillLogo skill="CSS" />
                  </Box>
                </Box>
                <Box flex={1} marginY={10} textAlign="center">
                  <Text variant="h4" marginBottom={3}>
                    Backend
                  </Text>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <SkillLogo skill="Nodejs" />
                    <SkillLogo skill="Expressjs" />
                    <SkillLogo skill="FastAPI" />
                  </Box>
                </Box>
              </Box>
              <Box
                id="experience"
                sx={{
                  minHeight: "85vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column"
                }}
                ref={experienceRef}
              >
                <Typography
                  variant="h4"
                  fontFamily="roboto slab"
                  marginBottom={3}
                  textAlign="center"
                >
                  Experience
                </Typography>
                {data.experience.map((job, i) => (
                  <JobBox job={job} key={i} />
                ))}
              </Box>
              <Box
                id="education"
                sx={{
                  minHeight: "70vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column"
                }}
                ref={educationRef}
              >
                <Typography
                  variant="h4"
                  fontFamily="roboto slab"
                  marginBottom={3}
                  textAlign="center"
                >
                  Education
                </Typography>
                {data.education.map((edu, i) => (
                  <EducationBox edu={edu} key={i} />
                ))}
              </Box>
              <Box
                id="certificates"
                sx={{
                  minHeight: "85vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  padding: 3,
                  paddingBottom: 15
                }}
                ref={certificatesRef}
              >
                <Typography
                  variant="h4"
                  fontFamily="roboto slab"
                  marginBottom={3}
                  textAlign="center"
                >
                  Certifications
                </Typography>
                {data.certifications.map((certificate, i) => (
                  <Box
                    key={i}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    marginY={2}
                    padding={2}
                    width="80%"
                    borderRadius={3}
                    sx={{
                      backgroundColor: "rgba(255, 255, 255, 0.05)",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                        transform: "scale(1.02)"
                      },
                      transition: "transform 0.2s ease-in-out, background-color 0.2s ease-in-out",
                      cursor: "pointer"
                    }}
                  >
                    <Typography fontFamily="roboto slab" variant="h5" gutterBottom>
                      {certificate.name}
                    </Typography>
                    <Typography
                      fontFamily="roboto slab"
                      variant="h6"
                      gutterBottom
                      color="rgba(255, 255, 255, 0.7)"
                    >
                      {certificate.institution}
                    </Typography>
                    <Link
                      href={certificate.link}
                      target="_blank"
                      underline="hover"
                      color="primary"
                      sx={{
                        fontFamily: "roboto slab",
                        fontSize: "1rem"
                      }}
                    >
                      View Certificate
                    </Link>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
        {/* End Content */}
      </Box>
    </Box>
  );
}

export default Experience;
