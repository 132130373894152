import { Box } from "@mui/material";
import { useEffect, useState, useRef, memo, useCallback } from "react";
import { useWindowSize, fetchData } from "../../utilities";
import aboutMeData from "../../data/aboutme.json";
// import "98.css";

const DragableBox = ({ children, title, startY }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [position, setPosition] = useState({ x: 100, y: startY });
  const [dragging, setDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const boxRef = useRef(null); // Ref to the draggable box

  const toggleExpand = () => setIsExpanded((prev) => !prev);

  // Start dragging when the user clicks the header
  const handleDragStart = (e) => {
    const boxRect = boxRef.current.getBoundingClientRect(); // Get the entire box position
    setDragOffset({
      x: e.clientX - boxRect.left, // Offset from where the user clicked on the box
      y: e.clientY - boxRect.top
    });
    setDragging(true);
  };

  // Stop dragging when the mouse is released
  const handleDragEnd = () => {
    setDragging(false);
  };

  // Move the box as the user moves the mouse
  const handleMouseMove = (e) => {
    if (dragging) {
      setPosition({
        x: e.clientX - dragOffset.x,
        y: e.clientY - dragOffset.y
      });
    }
  };

  // Add global mousemove and mouseup listeners when dragging starts
  useEffect(() => {
    if (dragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleDragEnd);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleDragEnd);
    }

    // Cleanup event listeners on component unmount or when dragging stops
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleDragEnd);
    };
  }, [dragging]);

  return (
    <div
      className="window" // 98.css window class
      style={{
        width: !isExpanded ? "180px" : "300px",
        position: "absolute",
        left: `${position.x}px`,
        top: `${position.y}px`
      }}
      ref={boxRef}
    >
      {/* Window Title Bar */}
      <div className="title-bar" onMouseDown={handleDragStart}>
        <div className="title-bar-text">{title || "A Window With Stuff In It"}</div>
        <div className="title-bar-controls">
          <button aria-label="Minimize"></button>
          <button aria-label="Maximize" onClick={toggleExpand}></button>
          <button aria-label="Close"></button>
        </div>
      </div>

      {/* Window Body */}
      <div className="window-body">
        {/* Children elements will be displayed here */}
        {isExpanded && <div>{children}</div>}
      </div>
    </div>
  );
};

const HeaderBox = () => {
  const links = [
    { title: "Brandon McHugh", to: "/" },
    { title: "About Me", to: "/aboutme" },
    { title: "Experience", to: "/experience" },
    { title: "Projects", to: "/projects" }
  ];

  return (
    <DragableBox title="Brandon McHugh" startY={100}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        {links.map((link, i) => (
          <button key={i} onClick={() => window.open(link.to)} style={{ width: "200px" }}>
            {link.title}
          </button>
        ))}
      </div>
    </DragableBox>
  );
};

const AboutMeBox = ({ data }) => {
  return (
    <DragableBox title="About Me" startY={200}>
      {data.paragraph.map((line, i) => (
        <p key={i} style={{ fontSize: "1.3em" }}>
          {line}
        </p>
      ))}
    </DragableBox>
  );
};

const HobbiesBox = ({ data }) => {
  return (
    <DragableBox title="Hobbies" startY={300}>
      {data.hobbies.map((hobby, i) => (
        <p key={i} style={{ fontSize: "1.3em" }}>
          {hobby}
        </p>
      ))}
    </DragableBox>
  );
};

const SettingsBox = ({
  startTime,
  reset,
  random,
  speed,
  handleSpeedChange,
  handleShowGrid,
  handleGlobal,
  timeGoing,
  global,
  showGrid,
  organismSize,
  handleChangeWorldSize
}) => {
  useEffect(() => {
    import("98.css");
  }, []);
  return (
    <DragableBox title="Settings" startY={400}>
      <button onClick={startTime}>{timeGoing ? "Pause Time" : "Start Time"}</button>
      <button onClick={reset}>Reset</button>
      <button onClick={random}>Random World</button>
      <div class="field-row" style={{ width: "250px", margin: "15px" }}>
        <label for="volume">Speed:</label>
        <label for="rangeLow">Slow</label>
        <input
          id="volume"
          type="range"
          min="1"
          max="100"
          defaultValue="50"
          value={speed}
          onChange={handleSpeedChange}
        />
        <label for="rangeHigh">Fast</label>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <p>World Type</p>
          <div className="field-row">
            <input
              id="radio_globe"
              type="radio"
              name="world_type"
              checked={global} // This will check the "Globe" radio button
              onChange={handleGlobal} // Toggle global state
            />
            <label htmlFor="radio_globe">Globe</label>
          </div>
          <div className="field-row">
            <input
              id="radio_contained"
              type="radio"
              name="world_type"
              checked={!global} // This will check the "Contained" radio button
              onChange={handleGlobal} // Toggle global state
            />
            <label htmlFor="radio_contained">Contained</label>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <p>Show Grid</p>
          <div className="field-row">
            <input
              id="radio_show"
              type="radio"
              name="grid_option"
              checked={showGrid} // Checked if showGrid is true
              onChange={handleShowGrid} // Toggle showGrid state
            />
            <label htmlFor="radio_show">Show</label>
          </div>
          <div className="field-row">
            <input
              id="radio_hide"
              type="radio"
              name="grid_option"
              checked={!showGrid} // Checked if showGrid is false
              onChange={handleShowGrid} // Toggle showGrid state
            />
            <label htmlFor="radio_hide">Hide</label>
          </div>
        </div>
        {/* <div style={{ flex: 1 }}>
          <p>World Size</p>
          <div className="field-row">
            <input
              id="radio_small"
              type="radio"
              name="speed_option"
              checked={organismSize === 20}
              onChange={handleChangeWorldSize}
            />
            <label htmlFor="radio_small">Small</label>
          </div>
          <div className="field-row">
            <input
              id="radio_big"
              type="radio"
              name="speed_option"
              checked={organismSize === 10}
              onChange={handleChangeWorldSize}
            />
            <label htmlFor="radio_big">Big</label>
          </div>
          <div className="field-row">
            <input
              id="radio_huge"
              type="radio"
              name="speed_option"
              checked={organismSize === 5}
              onChange={handleChangeWorldSize}
            />
            <label htmlFor="radio_huge">Huge</label>
          </div>
        </div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "flex-end"
          }}
        >
          <button
            onClick={() =>
              window.open("https://en.wikipedia.org/wiki/Conway%27s_Game_of_Life", "_blank")
            }
          >
            ?
          </button>
        </div>
      </div>
    </DragableBox>
  );
};

function lookAround([i, k], max, useBorders) {
  let { height, width } = max;
  const around = [];
  const positions = [-1, 0, 1];

  if (useBorders) {
    positions.forEach((dx) => {
      positions.forEach((dy) => {
        if (dx !== 0 || dy !== 0) {
          const ni = i + dx;
          const nk = k + dy;

          // Only add neighbors that are within the grid boundaries
          if (ni >= 0 && ni < height && nk >= 0 && nk < width) {
            around.push([ni, nk]);
          }
        }
      });
    });
  } else {
    positions.forEach((dx) => {
      positions.forEach((dy) => {
        if (dx !== 0 || dy !== 0) {
          const ni = (i + dx + height) % height;
          const nk = (k + dy + width) % width;
          around.push([ni, nk]);
        }
      });
    });
  }

  return around;
}

const Cell = memo(({ x, y, isAlive, toggleCell, organismSize, showGrid }) => {
  return (
    <Box
      onClick={() => toggleCell(x, y)} // Toggle cell state on click
      height={organismSize}
      width={organismSize}
      sx={{ boxSizing: "border-box" }}
      border={showGrid && "1px solid #DCDCDC"}
      backgroundColor={isAlive ? "black" : "white"}
    />
  );
});

const Life = () => {
  document.body.style.overflow = "hidden";
  const [data, setData] = useState(aboutMeData);
  const [organismSize, setOrganismSize] = useState(20);
  const { width, height } = useWindowSize();
  const [aliveCells, setAliveCells] = useState(new Set());
  const [timeGoing, setTimeGoing] = useState(false);
  const [speed, setSpeed] = useState(50);
  const [showGrid, setShowGrid] = useState(true);
  const [global, setGlobal] = useState(true);
  const worldSize = {
    height: Math.ceil(height / organismSize),
    width: Math.ceil(width / organismSize)
  };

  const handleGlobal = () => setGlobal(!global);
  const handleSpeedChange = (e) => setSpeed(e.target.value);
  const handleShowGrid = () => setShowGrid(!showGrid);
  const startTime = () => setTimeGoing((prev) => !prev);
  const handleChangeWorldSize = (e) => {
    switch (e.target.id) {
      case "radio_small":
        setOrganismSize(20);
        break;
      case "radio_big":
        setOrganismSize(10);
        break;
      case "radio_huge":
        setOrganismSize(5);
        break;
    }
  };

  const reset = () => {
    setTimeGoing(false);
    setAliveCells(new Set());
  };

  const random = () => {
    setTimeGoing(false);
    const newAliveCells = new Set();
    for (let i = 0; i < worldSize.height; i++) {
      for (let k = 0; k < worldSize.width; k++) {
        if (Math.random() < 0.5) {
          newAliveCells.add(`${i},${k}`);
        }
      }
    }
    setAliveCells(newAliveCells);
  };

  const toggleCell = useCallback((x, y) => {
    const key = `${x},${y}`;
    setAliveCells((prevAliveCells) => {
      const newAliveCells = new Set(prevAliveCells);
      if (newAliveCells.has(key)) {
        newAliveCells.delete(key);
      } else {
        newAliveCells.add(key);
      }
      return newAliveCells;
    });
  }, []);

  const evolve = () => {
    const newAliveCells = new Set();
    const potentialCells = new Map();

    aliveCells.forEach((cell) => {
      const [x, y] = cell.split(",").map(Number);
      const neighbors = lookAround([x, y], worldSize, !global);

      neighbors.forEach(([i, k]) => {
        const key = `${i},${k}`;
        potentialCells.set(key, (potentialCells.get(key) || 0) + 1);
      });
    });

    potentialCells.forEach((count, cell) => {
      const isAlive = aliveCells.has(cell);
      if ((isAlive && (count === 2 || count === 3)) || (!isAlive && count === 3)) {
        newAliveCells.add(cell);
      }
    });

    setAliveCells(newAliveCells);
  };

  useEffect(() => {
    if (timeGoing && aliveCells.size > 0) {
      const interval = setInterval(evolve, 1050 - speed * 10);
      return () => clearInterval(interval);
    } else if (aliveCells.size === 0) {
      setTimeGoing(false);
    }
  }, [timeGoing, aliveCells, speed]);

  // useEffect(() => {
  //   const getData = async () => {
  //     const res = await fetchData("/data/aboutme");
  //     console.log(res);
  //     res && setData(res);
  //   };
  //   getData();
  // }, []);

  return (
    <Box maxHeight="100vh">
      <Box>
        {Array.from({ length: worldSize.height }, (_, x) => (
          <Box display="flex" key={x}>
            {Array.from({ length: worldSize.width }, (_, y) => {
              const isAlive = aliveCells.has(`${x},${y}`);
              return (
                <Cell
                  key={y}
                  x={x}
                  y={y}
                  isAlive={isAlive}
                  toggleCell={toggleCell}
                  organismSize={organismSize}
                  showGrid={showGrid}
                />
              );
            })}
          </Box>
        ))}
      </Box>
      <HeaderBox />
      <AboutMeBox data={data} />
      <HobbiesBox data={data} />
      <SettingsBox
        startTime={startTime}
        reset={reset}
        random={random}
        speed={speed}
        handleSpeedChange={handleSpeedChange}
        handleShowGrid={handleShowGrid}
        handleGlobal={handleGlobal}
        global={global}
        timeGoing={timeGoing}
        showGrid={showGrid}
        organismSize={organismSize}
        handleChangeWorldSize={handleChangeWorldSize}
      />
    </Box>
  );
};

export default Life;
