import React from "react";

export async function fetchData(url) {
  const API_URL = process.env.REACT_APP_API_URL;

  return await fetch(API_URL + url)
    .then((res) => {
      if (res.headers.get("Content-Type").includes("image")) {
        return res.blob();
      } else {
        return res.json();
      }
    })
    .catch((e) => console.error(e));
}

export const randomColor = () => {
  const options = "0123456789ABCDEF";
  let randomColor = "#";
  for (let i = 0; i < 6; i++) {
    const x = Math.floor(Math.random() * 16);
    randomColor += options[x];
  }
  return randomColor;
};

export const randomKeyGenerator = () => {
  let s = "";

  for (let i = 0; i < 5; i++) {
    s = `${s}${String.fromCharCode(Math.random() * 94 + 33)}`;
  }

  return s;
};

export function capitalize(str) {
  if (Array.isArray(str)) {
    return str.map((c) => capitalize(c));
  } else {
    return str
      .split(" ")
      .reduce((acc, curr) => {
        let tempStr = curr.split("");
        tempStr[0] = tempStr[0].toUpperCase();
        return [...acc, tempStr.join("")];
      }, [])
      .join(" ");
  }
}

export function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

export function useWindowSize() {
  // const isSSR = typeof window !== "undefined";
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  function changeWindowSize() {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  }

  React.useEffect(() => {
    window.addEventListener("resize", changeWindowSize);

    return () => {
      window.removeEventListener("resize", changeWindowSize);
    };
  }, []);

  return windowSize;
}
