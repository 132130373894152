import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./routes/main";
import AboutMe from "./routes/aboutme/aboutme";
import Projects from "./routes/projects";
import Experience from "./routes/experience";
import WeatherApp from "./routes/projects/weather/weather";
import DrumMachine from "./routes/projects/drumMachine/drumMachine";
import { RandomQuoteMachine } from "./routes/projects/randomQuoteMachine/randomQuoteMachine";
import MarkdownPreviewer from "./routes/projects/markdownPreviewer/markdownPreviewer";
import JavascriptCalulator from "./routes/projects/javascriptCalculator/javascriptcalculator";
import Timer from "./routes/projects/Timer/timer";
import Minesweeper from "./routes/projects/minesweeper/App_minesweeper";
import Life from "./routes/projects/life/life";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/aboutme" element={<AboutMe />} />
        <Route path="/experience" element={<Experience />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/weatherapp" element={<WeatherApp />} />
        <Route path="/projects/drummachine" element={<DrumMachine />} />
        <Route path="/projects/randomquotemachine" element={<RandomQuoteMachine />} />
        <Route path="/projects/markdownpreviewer" element={<MarkdownPreviewer />} />
        <Route path="/projects/javascriptcalulator" element={<JavascriptCalulator />} />
        <Route path="/projects/timer" element={<Timer />} />
        <Route path="/projects/minesweeper" element={<Minesweeper />} />
        <Route path="/projects/life" element={<Life />} />
      </Routes>
    </Router>
  );
}

export default App;
