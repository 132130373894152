import { useEffect, useState } from "react";
import { Container, Typography, Box, Button, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fetchData, randomColor } from "../utilities";
import "../App.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import fallbackData from "../data/main.json";

function Main() {
  const [picBackground, setPicBackground] = useState("/static/yellowstone.jpg");

  const navigate = useNavigate();

  const navigateAboutMe = () => {
    navigate("/aboutme");
  };
  const navigateExperience = () => {
    navigate("/experience");
  };
  const navigateProjects = () => {
    navigate("/projects");
  };

  const openGithub = () => {
    window.open("https://github.com/brandmch", "_blank");
  };
  const openLinkedIn = () => {
    window.open("https://www.linkedin.com/in/mchugh-brandon/", "_blank");
  };
  const openInstagram = () => {
    window.open("https://www.instagram.com/brandmch2/", "_blank");
  };

  const subSectionButtonSx = () => ({
    backgroundColor: "black",
    marginX: 1,
    marginY: 5,
    fontFamily: "roboto slab",
    "&:hover": {
      backgroundColor: randomColor()
    }
  });

  const iconButtonSx = () => ({
    color: "#FFFFFF",
    boxShadow: "none",
    marginX: 1,
    "&:hover": {
      backgroundColor: randomColor()
    },
    "&:active": {
      boxShadow: "none"
    }
  });

  return (
    <Box
      sx={{
        position: "relative",
        height: "100vh",
        overflow: "hidden"
      }}
    >
      {picBackground && (
        <Box
          sx={{
            backgroundImage: `url(${picBackground})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
            "::after": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)" // Adjust the opacity as needed
            }
          }}
        />
      )}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        marginTop={6}
        sx={{ color: "white", textAlign: "center" }}
      >
        <Typography variant="h1" fontFamily="roboto slab" fontWeight={400} gutterBottom>
          Brandon McHugh
        </Typography>
        <Typography variant="h3" fontFamily="roboto slab" gutterBottom>
          Software Engineer
        </Typography>
        <Typography variant="h4" fontFamily="roboto slab" gutterBottom>
          Phoenix, Arizona
        </Typography>
        <Box>
          <Button onClick={navigateAboutMe} variant="contained" sx={subSectionButtonSx}>
            About Me
          </Button>
          <Button onClick={navigateExperience} variant="contained" sx={subSectionButtonSx}>
            Experience
          </Button>
          <Button onClick={navigateProjects} variant="contained" sx={subSectionButtonSx}>
            Projects
          </Button>
        </Box>
        <Box>
          <IconButton aria-label="GitHub" onClick={openGithub} sx={iconButtonSx}>
            <GitHubIcon sx={{ fontSize: 35 }} />
          </IconButton>
          <IconButton aria-label="Instagram" onClick={openInstagram} sx={iconButtonSx}>
            <InstagramIcon sx={{ fontSize: 35 }} />
          </IconButton>
          <IconButton aria-label="LinkedIn" onClick={openLinkedIn} sx={iconButtonSx}>
            <LinkedInIcon sx={{ fontSize: 35 }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default Main;
